.registration-student {
    padding: var(--p-xl) 0;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: var(--p-m);

        @include respond(small) {
            flex-direction: column;
            align-items: flex-start;
        }


        div {
            flex: 1;
            padding-right: var(--p-m);

            @include respond(small) {
                padding: 0;
            }
        }

        a {
            flex-basis: 41rem;

            @include respond(small) {
                width: 80%;
                flex-basis: auto;
                align-self: center;
                margin-top: var(--p-m);
            }
        }
    }
}